<template>
  <div class="'ScriptaDetails'">
    <div v-if="loading || !scripta">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-if="scripta" v-bind:title="scriptaIdnew" :pretitle="'Scripta Details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.scripta.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.scripta.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button v-if="!$store.getters.checkRole('legam.bib.scripta.modify') && $store.getters.checkRole('legam.bib.scripta.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Scripta Code</h6>
                  <b-form-input
                    id="input-type"
                    v-model="scriptaIdnew"
                    placeholder="Scripta Code"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="scriptaIdnew"></span>
                </b-col>
                <b-col>
                  <h6>Full Description</h6>
                  <b-form-input
                    id="input-type"
                    v-model="scripta.name"
                    placeholder="Full Description"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="scripta.name"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-input
                    id="input-comment"
                    v-model="scripta.comment"
                    placeholder="Comment"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="scripta.comment"></span>
                </b-col>
              </b-row>

              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 class="text-left">
                      Locations / Scriptoria <small>({{ scripta.locations.length }} {{ scripta.locations.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <b-card-text>

                          <LegamDataTables
                            v-bind="$data"
                            :additionalSettings="additionalSettings"
                          />

                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>

            </article>
            <div class="mt-4" v-if="config.editable">
              <h6>Logs</h6>
              <ChangeLogsTable :objectType="'scripta'" :objectId="scriptaId" :key="'logs' + index" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  * >>> .hidden {
    display: none;
  }

</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import config from '@/config.js'

  export default {
    name: 'ScriptaDetails',
    data() {
      return {
        columns: [
          { title: 'Location', field: 'display_raw', sortable: true, searchable: true },
          { title: 'Scriptoria', field: 'scriptoria', sortable: true, searchable: true },
          { title: '-', field: 'id', thClass: 'hidden', tdClass: 'hidden',
            transformData: function (){
              return ''
            }
          }
        ],
        JSONData: null,
        key: 1,
        searchableByColumn: true,
        additionalSettings: {
          order: [[1, 'asc']]
        },
        scriptaId: this.$route.params.id,
        scriptaIdnew: null,
        allData: null,
        loading: true,
        editMode: false,
        index: 0,
        config: config,
      }
    },
    components: {
      PageTitle,
      ChangeLogsTable,
      LegamDataTables
    },
    computed: {
      ...mapState({
        scripta: state => state.scripta.scripta
      })
    },
    mounted() {
      this.$store.dispatch('loadScripta', this.scriptaId)
    },
    watch: {
      'id'() {
        this.loading = true
        this.$store.dispatch('loadScripta', this.scriptaId)
      },
      'scriptaIdnew'() {
        this.scripta.code_new = this.scriptaIdnew
      },
      'scripta'() {
        this.loading = false
        this.JSONData = this.scripta.locations.map(location => { return {
          display_raw: location.display_raw,
          scriptoria: location.scriptoria.map(scriptorium => scriptorium.abbreviation).join(", ")
        }})
        this.scriptaIdnew = this.scripta.code
      }
    },
    methods: {
      // addManuscript(manuscript){
      //   this.$bvModal.hide('modal-add-manuscript')
      //   this.$store.dispatch('addNotification', {
      //     type: 'success',
      //     text: `Manuscript added`
      //   })
      //   this.scripta.manuscripts.push(manuscript)
      // },
      // deleteManuscript(manuscriptId){
      //   this.scripta.manuscripts = this.scripta.manuscripts.filter(
      //     manuscript => manuscript.id != manuscriptId
      //   )
      // },

      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadScripta', this.scriptaId)
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveScripta', this.scripta).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
              router.push(`/scripta/${this.scriptaIdnew}`)
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteScripta', this.scripta).then(() => {
              router.push('/scriptoria')
            })
          }
        })
      }
    }
  }
</script>
